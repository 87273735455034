/* ROOM PAGE SLIDER */

/* gap */
.room-details-slider .slick-slide {
    padding: 0 0.5rem 2.5rem; /* this is so we don't break shadows */
    box-sizing: border-box;
}

/* arrows on inside */
.room-details-slider .slick-prev {
    left: 0;
    z-index: 1;
}
.room-details-slider .slick-next {
    right: 0;
}

/* margins left/right to not break shadows */
.room-details-slider .slick-list {
    margin: 0 5rem;
}

/* dot size */
.room-details-slider .slick-dots li button:before {
    font-size: 12px;
}

/* HOME PAGE SLIDER */

/* gap */
.home-slider .slick-slide {
    padding: 0 0.5rem 1.5rem; /* this is so we don't break shadows */
    box-sizing: border-box;
}

/* arrows on inside */
.home-slider .slick-prev {
    left: -1rem;
    z-index: 1;
}
.home-slider .slick-next {
    right: 1rem;
    z-index: 1;
}

/* margins left/right to not break shadows */
.home-slider .slick-list {
    margin: 0 -0.5rem;
}

/* dot size */
.home-slider .slick-dots li button:before {
    font-size: 12px;
}

.home-slider .slick-dots {
    position: relative;
    bottom: 0;
}
